.h{
background-size: cover;
height: 450px;;
}
.do{
text-align: center;

}
.f a{
    text-decoration: none;
    list-style: none;
    color:black;
    
}
.b{
    margin-left: 4px;
    margin-right: 4px;
}

.user{
    width: 400px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 8px;
border: 1px solid black;
padding-left: 20px;
  }
.height .user{
    height: 70px;
    
}
.ValidationColor{
    color: red;
    margin-left: 15px;
    margin-top: 0;
}
.addp{
    color: black;
}
